<template>
  <div class="search">
    <div v-if="!selected && !loadingSingle">
      <b-form-input class="mb-0" :disabled="disabled" :placeholder="`Search for ${this.module.replace('-', ' ')}...`" tabindex="0" :debounce="500" :id="`search-field-${this._uid}`" @update="search" v-model="term"></b-form-input>
      <b-popover class="p-0 w-100" placement="bottom" :target="`search-field-${this._uid}`" triggers="focus" id="search-results">
        <div class="text-center">
          <p class="my-3 text-muted" v-if="!loading && !results.length">Please start typing to search for {{ module.replace('-', ' ') }}.</p>
          <b-spinner class="mx-4 my-2" v-if="loading || !results"></b-spinner>
        </div>
        <b-list-group v-if="!loading && results">
          <b-list-group-item @click.prevent="select(result.id)" href="#" :key="result.id" v-for="result in results">
            <slot name="popover-list" :result="result" />
          </b-list-group-item>
        </b-list-group>
      </b-popover>
    </div>
    <b-card body-class="d-flex flex-row p-2" v-if="selected || loadingSingle">
      <div class="d-flex flex-column flex-fill justify-content-center mr-2">
        <b-spinner class="m-2" v-if="loadingSingle" />
        <slot name="selected" :selected="selected" v-if="!loadingSingle" />
      </div>
      <b-button @click.prevent="selected = null" variant="primary" v-if="!disabled"><b-icon icon="pencil-fill" /></b-button>
      <b-button class="ml-2" @click.prevent="selected = null; $emit('delete', null)" variant="danger" v-if="!disabled && showDelete"><b-icon icon="trash-fill" /></b-button>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  async mounted () {
    if (this.value > 0) {
      this.loadingSingle = true
      await this.fetchSingle(this.returnObject ? this.value.id : this.value).then(({ data }) => {
        this.loadingSingle = false
        this.selected = data
      })
    }
  },
  props: {
    disabled: Boolean,
    fetchParams: {
      type: Object,
      default: () => ({})
    },
    module: String,
    action: String,
    singleAction: String,
    returnObject: {
      type: Boolean,
      default: false
    },
    showDelete: {
      type: Boolean,
      default: false
    },
    value: [Number, Object]
  },
  data () {
    return {
      loadingSingle: false,
      selected: null,
      term: ''
    }
  },
  computed: {
    ...mapState({
      results (state) {
        return state[this.module].data
      },
      loading (state) {
        return state[this.module].loading
      }
    })
  },
  methods: {
    ...mapActions({
      fetch (dispatch, payload) {
        return dispatch(`${this.module}/${this.action}`, payload)
      },
      fetchSingle (dispatch, payload) {
        return dispatch(`${this.module}/${this.singleAction}`, payload)
      }
    }),
    select (id) {
      this.selected = this.results.find(x => x.id === id)
      this.$emit('input', this.returnObject ? this.selected : id)
    },
    search () {
      return this.fetch({ query: this.term, ...this.fetchParams })
    }
  },
  watch: {
    value (v) {
      if (!v || (typeof v === 'object' && Object.keys(v).length === 0)) {
        this.selected = null
        this.term = ''
        return
      }

      this.fetchSingle(this.returnObject ? v.id : v).then(({ data }) => {
        this.loadingSingle = false
        this.selected = data
      })
    }
  }
}
</script>

<style scoped>
.search-results {
  width: 100%;
}

div.popover {
  max-width: 500px!important;
  width: 100%;
}
</style>
