<template>
  <resource-search @delete="() => this.$emit('delete')" @input="id => this.$emit('input', id)"
    action="fetchManySites" single-action="fetchSite" module="security-sites"
    :show-delete="showDelete" :value="value" :return-object="returnObject">

    <template #popover-list="{ result }">
      <p class="font-weight-bold mb-0">{{ result.customer.company_name }}</p>
      <p class="text-muted mb-0"><small>{{ result.address.address_snippet }}</small></p>
    </template>

    <template #selected="{ selected }">
      <p class="font-weight-bold mb-0">{{ selected.customer.company_name }}</p>
      <p class="text-muted mb-0"><small>{{ selected.address.address_snippet }}</small></p>
    </template>

  </resource-search>
</template>

<script>
import ResourceSearch from './ResourceSearch.vue'

export default {
  components: { ResourceSearch },
  props: {
    showDelete: {
      type: Boolean,
      default: false
    },
    returnObject: {
      type: Boolean,
      default: false
    },
    value: [Number, Object]
  }
}
</script>

<style>

</style>
