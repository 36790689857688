import {
  SECURITY_SITES_BATCH_DELETE_REQUEST,
  SECURITY_SITES_BATCH_DELETE_SUCCESS,
  SECURITY_SITES_BATCH_DELETE_FAILURE,
  SECURITY_SITES_FETCH_MANY_REQUEST,
  SECURITY_SITES_FETCH_MANY_SUCCESS,
  SECURITY_SITES_FETCH_MANY_FAILURE,
  SECURITY_SITES_FETCH_REQUEST,
  SECURITY_SITES_FETCH_SUCCESS,
  SECURITY_SITES_FETCH_FAILURE,
  SECURITY_SITES_SAVE_REQUEST,
  SECURITY_SITES_SAVE_SUCCESS,
  SECURITY_SITES_SAVE_FAILURE,
  SECURITY_SITES_SAVE_NOTE_REQUEST,
  SECURITY_SITES_SAVE_NOTE_SUCCESS,
  SECURITY_SITES_SAVE_NOTE_FAILURE,
  SECURITY_SITES_UPLOAD_REQUEST,
  SECURITY_SITES_UPLOAD_SUCCESS,
  SECURITY_SITES_UPLOAD_FAILURE
} from './types'

export default {
  reset ({ commit }) {
    commit(SECURITY_SITES_FETCH_SUCCESS, { data: {} })
  },
  deleteManySites ({ commit }, { ids = [] }) {
    commit(SECURITY_SITES_BATCH_DELETE_REQUEST)
    return window.axios.post('/security-sites', { _method: 'DELETE', ids }).then(response => {
      commit(SECURITY_SITES_BATCH_DELETE_SUCCESS, response)
      return response
    }).catch(error => {
      commit(SECURITY_SITES_BATCH_DELETE_FAILURE, error)
      throw error
    })
  },
  fetchManySites ({ commit }, { customerId = null, query = '', page = 1 }) {
    commit(SECURITY_SITES_FETCH_MANY_REQUEST)
    const params = { query, page }
    if (customerId) {
      params.customer_id = customerId
    }
    return window.axios.get('/security-sites', { params }).then(response => {
      commit(SECURITY_SITES_FETCH_MANY_SUCCESS, response)
      return response
    }).catch(error => {
      commit(SECURITY_SITES_FETCH_MANY_FAILURE, error)
      throw error
    })
  },
  fetchSite ({ commit }, id) {
    commit(SECURITY_SITES_FETCH_REQUEST)
    return window.axios.get(`/security-sites/${id}`).then(response => {
      commit(SECURITY_SITES_FETCH_SUCCESS, response)
      return response
    }).catch(error => {
      commit(SECURITY_SITES_FETCH_FAILURE, error)
      throw error
    })
  },
  saveSite ({ commit }, payload) {
    const path = payload.id ? `/security-sites/${payload.id}` : '/security-sites'
    const method = payload.id ? 'put' : 'post'

    commit(SECURITY_SITES_SAVE_REQUEST)
    return window.axios[method](path, payload).then(response => {
      commit(SECURITY_SITES_SAVE_SUCCESS, response)
      return response
    }).catch(error => {
      if (error.response.status === 422) {
        commit(SECURITY_SITES_SAVE_FAILURE, { validationErrors: error.response.data.errors })
      } else {
        commit(SECURITY_SITES_SAVE_FAILURE, { error })
      }
      throw error
    })
  },
  createNote ({ commit }, { siteId, content }) {
    commit(SECURITY_SITES_SAVE_NOTE_REQUEST)
    return window.axios.post('/security-customer-notes', { content, site_id: siteId }).then(response => {
      commit(SECURITY_SITES_SAVE_NOTE_SUCCESS, response)
      return response
    }).catch(error => {
      if (error.response.status === 422) {
        commit(SECURITY_SITES_SAVE_NOTE_FAILURE, { validationErrors: error.response.data.errors })
      } else {
        commit(SECURITY_SITES_SAVE_NOTE_FAILURE, { error })
      }
      throw error
    })
  },
  uploadDocument ({ commit }, { id, data }) {
    commit(SECURITY_SITES_UPLOAD_REQUEST)
    return window.axios.post(`/security-sites/${id}/upload`, data).then(response => {
      commit(SECURITY_SITES_UPLOAD_SUCCESS, response)
      return response
    }).catch(error => {
      if (error.response.status === 422) {
        commit(SECURITY_SITES_UPLOAD_FAILURE, { validationErrors: error.response.data.errors })
      } else {
        commit(SECURITY_SITES_UPLOAD_FAILURE, { error })
      }
      throw error
    })
  }
}
