<template>
  <resource-search @delete="() => this.$emit('delete')" @input="id => this.$emit('input', id)"
    action="fetchManyUsers" single-action="fetchUser" module="users"
    :show-delete="showDelete" :value="value" :return-object="returnObject">

    <template #popover-list="{ result }">
      <p class="font-weight-bold mb-0">{{ [result.forename, result.surname].join(' ') }}</p>
      <p class="text-muted mb-0"><small>{{ result.email }}</small></p>
    </template>

    <template #selected="{ selected }">
      <p class="font-weight-bold mb-0">{{ [selected.forename, selected.surname].join(' ') }}</p>
      <p class="text-muted mb-0"><small>{{ selected.email }}</small></p>
    </template>

  </resource-search>
</template>

<script>
import ResourceSearch from './ResourceSearch.vue'

export default {
  components: { ResourceSearch },
  props: {
    showDelete: {
      type: Boolean,
      default: false
    },
    returnObject: {
      type: Boolean,
      default: false
    },
    value: [Number, Object]
  }
}
</script>

<style>

</style>
