<template>
  <b-container>
    <b-row>
      <b-col cols="12" md="4">
        <b-button class="mr-2" to="/security/reports" variant="primary"><b-icon class="mr-2" icon="arrow-left" />Reports</b-button>
        <b-button class="mr-2" :href="reportCsvUrl" variant="secondary"><b-icon class="mr-2" icon="box-arrow-up-right" />Export (CSV)</b-button>
      </b-col>
      <b-col class="d-flex flex-row" cols="12" md="8">
        <b-input-group>
          <b-form-datepicker class="rounded-left" v-model="start"></b-form-datepicker>
          <b-input-group-text class="rounded-0 border-left-0 border-right-0"> &dash; </b-input-group-text>
          <b-form-datepicker class="rounded-right" v-model="end"></b-form-datepicker>
        </b-input-group>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col cols="12" md="4" offset-md="4">
        <security-site-search v-model="security_site_id" />
      </b-col>
      <b-col cols="12" md="4">
        <user-search v-model="user_id" />
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col cols="12" v-if="loading">
        <b-card body-class="py-5 text-center">
          <b-card-text>
            <b-spinner variant="secondary"></b-spinner>
            <p class="font-weight-normal mb-0 mt-2 text-muted">Loading Report...</p>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col cols="12" v-if="!loading">
        <b-table responsive striped head-variant="dark" :fields="fields" :items="values">
          <template #cell(user_name)="{ item }">
            <span :class="{'font-weight-bold': item.ind > 1}">{{ item.user_name }}</span>
          </template>
          <template #cell(quantity)="{ item }">
            <span :class="{'font-weight-bold': item.ind > 1}">{{ item.quantity }}</span>
          </template>
          <template #cell(cost)="{ item }">
            <span :class="{'font-weight-bold': item.ind > 1}">{{ item.cost !== "-" ? new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(item.cost) : '-' }}</span>
          </template>
          <template #cell(profit)="{ item }">
            <span :class="{'font-weight-bold': item.ind > 1}">{{ item.profit ? new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(item.profit) : '£0.00' }}</span>
          </template>
          <template #cell(deduction)="{ item }">
            <span :class="{'font-weight-bold': item.ind > 1}">{{ item.deduction ? new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(item.deduction) : '£0.00' }}</span>
          </template>
          <template #cell(commission)="{ item }">
            <span :class="{'font-weight-bold': item.ind > 1}">{{ item.commission ? new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(item.commission) : '£0.00' }}</span>
          </template>
          <template #cell(total)="{ item }">
            <span :class="{'font-weight-bold': item.ind > 1}">{{ item.total ? new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(item.total) : '£0.00' }}</span>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import securitySitesModule from '../../store/security-sites'
import usersModule from '../../store/users'
import SecuritySiteSearch from '../../components/SecuritySiteSearch.vue'
import UserSearch from '../../components/UserSearch.vue'
import moment from 'moment'

export default {
  components: { SecuritySiteSearch, UserSearch },
  data () {
    return {
      loading: false,
      user_id: null,
      security_site_id: null,
      start: this.moment().startOf('month').toDate(),
      end: this.moment().endOf('month').toDate(),
      fields: [
        { key: 'user_name', text: 'Details' },
        { key: 'quantity', text: 'Quantity' },
        { key: 'cost', text: 'Cost' },
        { key: 'profit', text: 'Profit' },
        { key: 'commission', text: 'Commission' },
        { key: 'deduction', text: 'Deduction' },
        { key: 'total', text: 'Total Commission' }
      ],
      values: []
    }
  },
  async mounted () {
    await this.load()
  },
  computed: {
    reportCsvUrl () {
      let rawParams = [['start', this.moment(this.start).format('YYYY-MM-DD')], ['end', this.moment(this.end).format('YYYY-MM-DD')]]
      if (this.user_id && this.user_id > 0) {
        rawParams = [...rawParams, ['user_id', this.user_id]]
      }
      if (this.security_site_id && this.security_site_id > 0) {
        rawParams = [...rawParams, ['site_id', this.security_site_id]]
      }
      const params = new URLSearchParams(rawParams)
      return `/api/reports/commission.csv?${params.toString()}`
    }
  },
  methods: {
    moment,
    async load () {
      this.loading = true
      let rawParams = [['start', this.moment(this.start).format('YYYY-MM-DD')], ['end', this.moment(this.end).format('YYYY-MM-DD')]]
      if (this.user_id && this.user_id > 0) {
        rawParams = [...rawParams, ['user_id', this.user_id]]
      }
      if (this.security_site_id && this.security_site_id > 0) {
        rawParams = [...rawParams, ['site_id', this.security_site_id]]
      }
      const params = new URLSearchParams(rawParams)
      const response = await window.axios.get('/reports/commission', { params })

      this.loading = false
      this.values = response.data.map((i) => ({
        ...(i.ind > 1 ? { _rowVariant: 'primary' } : { }),
        ...i
      }))
    }
  },
  watch: {
    async end () {
      await this.load()
    },
    async start () {
      await this.load()
    },
    async user_id () {
      await this.load()
    },
    async security_site_id () {
      await this.load()
    }
  },
  beforeCreate () {
    if (!this.$store.hasModule('security-sites')) {
      this.$store.registerModule('security-sites', securitySitesModule)
    }
    if (!this.$store.hasModule('users')) {
      this.$store.registerModule('users', usersModule)
    }
  },
  destroyed () {
    if (!this.$store.hasModule('security-sites')) {
      this.$store.unregisterModule('security-sites')
    }
    if (!this.$store.hasModule('users')) {
      this.$store.unregisterModule('users')
    }
  }
}
</script>

<style>

</style>
